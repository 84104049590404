import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import URI from 'urijs'
import {
  useDineInCart,
  useStores,
} from 'react-omnitech-api'
import DineInView from './dine-in-view'
import { useLink, useOrderMethod } from '../../hook'

function getQueryFromUrl(href) {
  const url = new URI(href)
  const query = url.search(true)
  return query
}

function DineInController(props) {
  const {
    location,
  } = props
  const { t } = useTranslation()
  const { navigate } = useLink()
  const {
    availableOrderMethod,
    clearOrderMethod,
    setOrderMethod,
    setStore,
    store: orderMethodStore,
    orderMethod,
  } = useOrderMethod()
  const { cart: dineInCart, createCart, resetStagingCart } = useDineInCart()
  const { fetchStore } = useStores()
  const [showCompleteMessage, setShowCompleteMessage] = useState(false)
  const [createCartInProgress, setCreateCartInProgress] = useState(false)
  const [createCartSuccess, setCreateCartSuccess] = useState(false)
  const [storeUpdateSuccess, setStoreUpdateSuccess] = useState(false)
  const dineInOrderMethod = _.find(availableOrderMethod, { code: 'dineIn' })

  const origOrderMethod = useMemo(() => orderMethod, [])
  const orderMehtodCode = useMemo(() => _.get(orderMethod, 'code'), [orderMethod])
  const url = _.get(location, 'href')

  const qrCodeData = useMemo(() => {
    const query = getQueryFromUrl(url)
    return query
  }, [url])

  useEffect(() => {
    if (_.isEmpty(qrCodeData)) {
      clearOrderMethod()
      resetStagingCart()
      setShowCompleteMessage(true)
    }
  }, [qrCodeData])
  useEffect(() => {
    if (
      createCartSuccess
      || _.isEmpty(qrCodeData)
      || showCompleteMessage
    ) return
    setOrderMethod(dineInOrderMethod)
    setCreateCartInProgress(true)
  }, [createCartSuccess, dineInOrderMethod, qrCodeData, setOrderMethod])

  useEffect(() => {
    if (!createCartInProgress || orderMehtodCode !== 'dineIn') return
    handleCreateDineInCart()
  }, [createCartInProgress, orderMehtodCode])

  const handleCreateDineInCart = async () => {
    const option = {
      params: {
        includes: [
          'cart_shipments',
          'cart_shipments.cart_lines',
          'cart_shipments.inventory_store',
          'cart_lines.sku_id',
          'carts.consumer',
          'carts.meta',
          'carts.internal_app',
          'internal_apps.hardware',
          'hardwares.store',
          'users.loyalty_information',
          'physical_store_id',
        ],
      },
      payload: {
        schemaVersion: '2021-06-08',
        data: {
          cart: {
            [_.get(qrCodeData, 'd', 'nfcData')]: qrCodeData,
          },
        },
      },
    }
    try {
      const includes = [
        'meta',
        'today_open_time_slots',
      ]
      const data = await createCart(option)
      const storeId = _.get(data, 'cart.physicalStoreId')
      const { store } = await fetchStore({ id: storeId, includes })
      // update store in localstorage (ensure only save store.id and store.code)
      setStore(_.pick(store, ['id', 'code']))
      setStoreUpdateSuccess(true)
    } catch (error) {
      console.log('///// catch error .....', error)
      // set orderMethod back to original
      setOrderMethod(origOrderMethod)
      // show error message
      setShowCompleteMessage(true)
    } finally {
      setCreateCartInProgress(false)
    }
  }

  useEffect(() => {
    // Make sure cart store and store in localstorage are same before continue dine-in
    if (
      createCartInProgress
      && storeUpdateSuccess
      && !createCartSuccess
      && !_.isEmpty(dineInCart)
      && !_.isEmpty(orderMethodStore)
      && _.get(dineInCart, 'physicalStoreId') === _.get(orderMethodStore, 'id')
    ) {
      setCreateCartSuccess(true)
      // Navigate to Categories Menu
      navigate('/categories/')
    }
  }, [createCartInProgress, createCartSuccess, orderMethodStore, storeUpdateSuccess, dineInCart])

  // [TODO]
  // - get qr code data from url
  // - create cart with qr code data
  // - update order method
  // - navigate to products page

  // local variable
  const seoTitle = t('screens.dineIn.seo.title')
  const viewProps = {
    createCartInProgress,
    seoTitle,
    showCompleteMessage,
  }

  return (
    <DineInView {...viewProps} />
  )
}

export default DineInController
